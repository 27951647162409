<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">KAAGAPAY ASSIGN OR SERIES</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="4"
              cols="12"
            ></v-col>
            <v-row>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="transmital_no"
                  label="Transmital #"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="stab_no"
                  label="Stab #"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="or_series"
                  label="OR Series"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="or_address"
                  label="OR Address"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="date_release"
                  dense
                  label="Date"
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  type="date"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="collector_id"
                  dense
                  outlined
                  label="Collector"
                  :items="collectors"
                  item-value="id"
                  item-text="full_name"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
            </v-row>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="assigned_or_to_collector"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      collectors: [],
      collector_id: 0,
      transmital_ors_id: 0,
      transmital_no: 0,
      stab_no: 0,
      or_series: '',
      or_address: '',
      date_release: '',
    };
  };
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    created() {
      this.initialize_data()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('staff', ['find_staff_with_position_active']),
      ...mapActions('transmital_or', ['get_available_or_to_assign_kaagapay', 'assign_to_collector_available_or']),
      initialize_data() {
        const data = new FormData()
        data.append('branch_id', this.branch_id);
        this.get_available_or_to_assign_kaagapay(data)
          .then(response => {
            if (Object.keys(response.data).length > 0) {
              this.transmital_ors_id = response.data.id
              this.transmital_no = response.data.transmital_no
              this.stab_no = response.data.stab_no
              this.or_series = response.data.start + '-' + response.data.end
              this.or_address = response.data.address
            }
          })
          .catch(error => {
            console.log(error)
          })
        data.append('positions', 'Collector');
        this.find_staff_with_position_active(data)
          .then(response => {
            this.collectors = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      reset() {
        Object.assign(this.$data, initialState())
        this.initialize_data()
      },
      assigned_or_to_collector() {
        this.saving = true
        this.alert = false
        if (this.transmital_ors_id === 0) {
          this.alert = true
          this.alert_message = 'Please request to Head Office (TO LOAD TRANSMITAL OR SERIES)'
          this.saving = false
        } else {
          if (this.$refs.form.validate()) {
            const data = new FormData()
            data.append('branch_id', this.branch_id);
            data.append('id', this.transmital_ors_id);
            data.append('collector_id', this.collector_id);
            data.append('date_release', moment(this.date_release, 'YYYY-MM-DD').format('MMMM D, YYYY'));
            this.assign_to_collector_available_or(data)
              .then(response => {
                var color = 'success'
                if (response.status === 201) {
                  color = 'error'
                }
                this.change_snackbar({
                  show: true,
                  color: color,
                  text: response.data,
                })
                this.reset()
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          } else {
            this.alert = true
            this.alert_message = 'Please Fill up the field/s'
            this.saving = false
          }
        }
      }
    }
  }
</script>
